



























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import { FETCH_ACCOUNTING_LIST, GET_ACCOUNTINGS } from '@/modules/listOfAssets/store';
import { Accounting, ListOfAssets } from '@/modules/listOfAssets/types';
import { addDays, format, getYear } from '@/filter/dateFormat';
import AccountingDownloadAction from '@/modules/listOfAssets/components/AccountingDownloadAction.vue';
import FinalInvoiceDownloadAction from '@/modules/listOfAssets/components/FinalInvoiceDownloadAction.vue';
import AppTable from '@/components/AppTable.vue';

const CreateAnnualAccountingDialog = () => import('@/modules/listOfAssets/components/details/dialogs/CreateAnnualAccountingDialog.vue');
const CorrectAnnualAccountingDialog = () => import('@/modules/listOfAssets/components/details/dialogs/CorrectAnnualAccountingDialog.vue');
const RemoveAnnualAccountingDialog = () => import('@/modules/listOfAssets/components/details/dialogs/RemoveAnnualAccountingDialog.vue');
const FinalInvoiceDialog = () => import('@/modules/listOfAssets/components/details/dialogs/FinalInvoiceDialog.vue');

const ListOfAssets = namespace('listOfAssets');

@Component({
  components: {
    AppTable,
    FinalInvoiceDownloadAction,
    AccountingDownloadAction,
    CreateAnnualAccountingDialog,
    CorrectAnnualAccountingDialog,
    RemoveAnnualAccountingDialog,
    FinalInvoiceDialog
  }
})
export default class AccountingCard extends Vue {
  @Prop({ type: Object }) listOfAssets!: ListOfAssets;

  @ListOfAssets.Getter(GET_ACCOUNTINGS) findByListOfAssets!: (conservatorshipId: string) => Accounting[];
  @ListOfAssets.Action(FETCH_ACCOUNTING_LIST) fetch!: (conservatorshipId: string) => Promise<ApiResponse>;

  loading: boolean = false;

  accounting: Accounting | null = null;
  removeAccounting: Accounting | null = null;

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.year'), sortable: false },
      { align: 'left', text: this.$i18n.t('common.date'), sortable: false },
      { align: 'left', text: this.$i18n.t('common.created'), sortable: false },
      { align: 'left', text: this.$i18n.t('common.actions'), sortable: false }
    ];
  }

  get nextStart() {
    const accountings = this
      .accountings
      .sort((first: Accounting, second: Accounting) => second.end.localeCompare(first.end));

    let latest: Accounting | null = null;

    if (accountings.length > 0) {
      latest = accountings[0];
    }

    if (latest) {
      return format(addDays(latest.end, 1), 'yyyy-MM-dd');
    }

    return this.listOfAssets.opened;
  }

  get accountings(): Accounting[] {
    return this.findByListOfAssets(this.listOfAssets.id).map((accounting) => ({
      ...accounting,
      endTrans: format(accounting.end, 'dd.MM.yyyy'),
      startTrans: format(accounting.start, 'dd.MM.yyyy'),
      type: 'accounting'
    }));
  }

  get finalInvoices(): Array<{ start: string; end: string; created: string; year: number; }> {
    if (this.listOfAssets.finalInvoice) {
      return [{
        end: format(this.listOfAssets.finalInvoice.closed, 'dd.MM.yyyy'),
        start: format(this.listOfAssets.finalInvoice.opened, 'dd.MM.yyyy'),
        created: format(this.listOfAssets.finalInvoice.created, 'dd.MM.yyyy'),
        year: getYear(this.listOfAssets.finalInvoice.closed),
      }];
    }

    return [];
  }

  created() {
    return this.fetch(this.listOfAssets.id);
  }
}
