















import { Component, Prop, Vue } from 'vue-property-decorator';
import { ListOfAssets } from '@/modules/listOfAssets/types';
import AccountingCard from '@/modules/listOfAssets/components/details/cards/AccountingCard.vue';

@Component({
  components: { AccountingCard }
})
export default class AccountingTab extends Vue {
  @Prop({ type: Object }) listOfAssets?: ListOfAssets;
}
