










import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import slugify from 'slugify';
import { FETCH_ACCOUNTING } from '../store';
import { ApiResponse } from '@/components/types';

const ListOfAssets = namespace('listOfAssets');

@Component({})
export default class AccountingDownloadAction extends Vue {
  @Prop({ type: String, required: true }) listOfAssetsId!: string;
  @Prop({ type: String, required: true }) id!: string;
  @Prop({ type: String, required: true }) createdDate!: string;
  @Prop({ type: String, default: 'primary' }) color!: string;
  @Prop({ type: Boolean, default: true }) icon!: boolean;
  @Prop({ type: String }) href?: string;

  @ListOfAssets.Action(FETCH_ACCOUNTING) fetchPDF!: ({ listOfAssetsId, id }: { listOfAssetsId: string, id: string }) => Promise<ApiResponse<Blob>>;

  error: any = null;
  loading: boolean = false;

  get filename() {
    return `${slugify(this.$i18n.t('common.accounting') as string)}-${this.createdDate}.pdf`;
  }

  async download() {
    this.error = null;
    this.loading = true;

    const { error, content } = await this.fetchPDF({
      listOfAssetsId: this.listOfAssetsId,
      id: this.id
    });

    if (error) {
      this.error = error;
      this.loading = false;
      return;
    }

    this.loading = false;

    const link = document.createElement('a');
    link.href = URL.createObjectURL(content);
    link.setAttribute('download', this.filename);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
